//@ts-ignore
import BusinessServiceRequest from "./BusinessServiceRequest.ts"

export default class BusinessServiceOrder {
    constructor() {
        return {
            id_order: 0,
            customer: '', //Sender,
            partners: [], //Receiver,
            type: '',
            carrierServiceType: '',
            description: '',
            comments: '',
            paymentMethod: '',
            timezone: '', //timezone,
            // service_request: BusinessServiceRequest,
            logistic: {
                collect: {
                    packages: [1,2], //Package[],
                    start_date: '',
                    transit_date: '',
                    delivery_date: '',
                    devolution_date: '',
                    items: [],
                    total_qty: 0,
                    total_weight: 0
                },
                shipping: {
                    packages: [1,2], //Package[],
                    start_date: '',
                    transit_date: '',
                    delivery_date: '',
                    devolution_date: '',
                    items: [],
                    total_qty: 0,
                    total_weight: 0
                },
                dispatch: {

                    routes: {
                        fromCustomer: '',
                        fromProvider: ''
                    }
                }
            },
            timeline: {

                created_at: '',
                request_date: '',
                deadline_date: '',
                order_date: '',
                accept_date: '',
                assigment_date: '',
                payment_date: '',
                completed_date: '',
                cancel_date: '',
                refund_date: ''
                
            },
        }
    }
}
