export default class ServiceConfiguration {

    constructor() {
        return {
            id_service_configuration: 0,
            id_service: 0,
            name: '',
            description: '',
            configuration: {

                pricing: {

                    unit: 'weight',
                    type: 'gr',
                    value: 0
                },
                packing: {
                    id: '',
                    orderId: '',
                    envelope: '',
                    dimensions: {
                         width: 0,
                         height: 0,
                         deep: 0,
                         area: 0
                    },
                    weight: '',
                    courier: '',
                    totalValue: 0,
                    items: []
                },
                transport: {
                    required_types: []
                },
                
                
            },
            
        }
    }
}