//@ts-ignore
import ServiceItem from './ServiceItem.ts';
//@ts-ignore
import ServiceConfiguration from './ServiceConfiguration.ts';

export default class Service {

    constructor() {

        return {
            id_service: 0,
            id_category: 0,
            name: '',
            description: '',
            created_at: '',
            is_active: 1,
            is_available: 1,
            code: '',
            img: '/img/04.jpg',
            title: 'Disfruta lo mejor de la pasteleria y reposteria gourmet con Cascabel',
            avatar: 'X CANTIDAD',
            userFullName: 'Validez',
            blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
            tags: {
                id_category_tags: 0,
                id_category: 0,
                tags: ''
            }, //['Alimentacion', 'Pasteleria', 'Cumpleaños'],
            excerpt: 'Torta Especial 100 CarboPuntos, Torta Natural 75 CarboPuntos',
            comment: 76,
            catalog: [],

            // configuration: ServiceConfiguration 
        }
    }
}