//@ts-ignore
import Attribute from './Attribute.ts';

export default class ServiceItem {

    constructor() {
        return {
            id_service: 0,
            id_service_item: 0,
            name: '',
            ean: '',
            refId: '',
            image: '/img/04.jpg',
            type: '',
            price: '',
            weight: '',
            quantity: 0,
            attributes: [Attribute]
        }
    }

}