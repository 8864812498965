export default class Category {

    constructor() {
        return {
            id_category: 0,
            created_at: '',
            is_active: 1,
            is_available: 1,
            code: 'LVC-0000',
            name: 'Nombre Categoria Nueva',
            description: 'Descripcion de la Categoria Nueva',
            img: '/img/04.jpg',
            title: 'Slogan de la Nueva Categoria',
            avatar: '@/assets/images/portrait/small/avatar-s-7.jpg',
            userFullName: 'Validez',
            blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
            tags: {
                id_category_tags: 0,
                id_category: 0,
                tags: ''
            },
            excerpt: 'Texto Especial',
            comment: 76,
            services: []
        }
    }
}