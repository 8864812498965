export default class Attribute {

    constructor() {
        return {
            id_attribute: 0,
            name: '',
            type: '',
            description: '',
            value: ''
        }
    }
}