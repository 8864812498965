export default class FilterObjects {

    constructor() {

    }

    filterArrayObject(arrayObject, keya, filterKey) {

        let objectMatch = [];
        let flag = true;

        arrayObject.forEach((objIterable, index) => {

            flag = true;

            Object.keys(objIterable[keya]).find((key) => {
    
                if(flag) {
                    if(((objIterable[keya])[key]+'').lastIndexOf(filterKey) !== -1) {
                        objectMatch.push(objIterable);
                        flag = false;
                    }
                }
            });
        });
        return objectMatch;
    }

    filterArrayObjectSingle(arrayObject, keya, filterKey) {

        let objectMatch = [];

        arrayObject.forEach((objIterable, index) => {

            let flag = true;

            if(flag) {
                if(objIterable[keya] === filterKey*1) {
                    objectMatch.push(objIterable);
                    flag = false;
                }
            }
        });
        return objectMatch;
    }

    paginateArrayObject(arrayObject, qtyByPage, pageLength) {

        let pagesQty = Math.ceil(arrayObject.length/qtyByPage)

        return {
            
            pagesQty: pagesQty,
            pageLength: pageLength

        }
    }
}